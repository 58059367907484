export function AppleIcon(props: React.ComponentProps<'svg'>) {
  return (
    <svg viewBox="0 0 20 21" fill="none" {...props}>
      <path
        d="M13.9201 10.3361C13.9141 9.1987 14.429 8.34075 15.4702 7.70847C14.8871 6.87475 14.0072 6.41587 12.8449 6.32576C11.7446 6.23944 10.5429 6.96714 10.1022 6.96714C9.63723 6.96714 8.57105 6.35681 7.73431 6.35681C6.00479 6.38482 4.16699 7.73573 4.16699 10.4852C4.16699 11.297 4.31541 12.136 4.61376 13.0015C5.00979 14.1396 6.44172 16.9285 7.93573 16.8823C8.7172 16.8634 9.26846 16.3273 10.2854 16.3273C11.2706 16.3273 11.7817 16.8823 12.6533 16.8823C14.1594 16.8604 15.455 14.3252 15.8337 13.1848C13.8119 12.2329 13.9209 10.3951 13.9209 10.3368L13.9201 10.3361ZM12.1664 5.24746C13.0122 4.24337 12.935 3.32864 12.91 3C12.1634 3.04392 11.2986 3.50886 10.8056 4.08208C10.2635 4.69544 9.94391 5.45494 10.0121 6.31062C10.8215 6.37271 11.5591 5.95699 12.1664 5.24746V5.24746Z"
        fill="black"
      />
    </svg>
  );
}
